import { BusinessOrgs } from '@/enums'
import { isString } from '@/utils'
import * as yup from 'yup'

type ValidationMessages = Record<string, string>

const makeEmployeeCountValidationObject = (messages: ValidationMessages) =>
  yup.object().shape({
    ownersCount: yup
      .number()
      .required(messages.general)
      .min(1, messages.positive),
    fullTimeEmployeesCount: yup.number().required(messages.general),
    partTimeEmployeesCount: yup.number().required(messages.general),
    miniJobbersCount: yup.number().required(messages.general),
  })

const makeGeneralValidationObject = (messages: ValidationMessages) => ({
  businessType: yup
    .string()
    .required(messages.general)
    .test({
      name: 'isValid',
      message: messages.general,
      test: (val) => Boolean(val),
    }),
  locationSubtypes: yup.array().of(yup.string()),
  locationType: yup.string().required(messages.general),
  locationTypeSpecification: yup.string().when('locationType', {
    is: (locationType: string) => locationType === 'other',
    then: yup.string().required(messages.general),
    otherwise: yup.string(),
  }),
  companyFoundedDate: yup.object().shape({
    foundedDate: yup
      .mixed()
      .test({
        name: 'isValid',
        message: messages.general,
        test: (value) => {
          if (isString(value)) {
            return yup.string().required(messages.general).isValidSync(value)
          } else {
            return yup.number().required(messages.general).isValidSync(value)
          }
        },
      })
      .required(messages.general),
  }),
})

const makeLIValidationSchema = (messages: ValidationMessages) =>
  yup.object().shape({
    ...makeGeneralValidationObject(messages),
    lastYearFinances: yup.object().shape({
      approxTurnover: yup.number().required(messages.general),
      approxPersonelExpenses: yup.number().required(messages.general),
    }),
    employeesCount: makeEmployeeCountValidationObject(messages),
  })

const makeCOValidationSchema = (messages: ValidationMessages) =>
  yup.object().shape({
    ...makeGeneralValidationObject(messages),
    lastYearFinances: yup.object().shape({
      approxTurnover: yup.number().required(messages.general),
      approxCostOfGoods: yup.number().required(messages.general),
    }),
    locationRent: yup.object().shape({
      totalEquipmentsCost: yup.number().required(messages.general),
    }),
    employeesCount: makeEmployeeCountValidationObject(messages),
    locationDetails: yup.object().shape({
      address: yup
        .object()
        .shape({
          city: yup.string().required(messages.general),
          postalCode: yup
            .string()
            .required(messages.general)
            .typeError(messages.postalCodeTypeError),
          street: yup.string().required(messages.general),
          streetNr: yup
            .string()
            .required(messages.general)
            .typeError(messages.general),
        })
        .typeError(messages.general),
      name: yup
        .string()
        .required(messages.general)
        .test('not-only-business-org', messages.nameInvalid, (value) => {
          if (!value) return false
          const businessOrgSuffixes = Object.values(BusinessOrgs)
          return !businessOrgSuffixes.includes(value.trim())
        }),
    }),
  })

const makeLEValidationSchema = (messages: ValidationMessages) =>
  yup.object().shape({
    ...makeGeneralValidationObject(messages),
    lastYearFinances: yup.object().shape({
      approxTurnover: yup.number().required(messages.general),
    }),
    employeesCount: makeEmployeeCountValidationObject(messages),
  })

const makeCYValidationSchema = (messages: ValidationMessages) =>
  yup.object().shape({
    ...makeGeneralValidationObject(messages),
    lastYearFinances: yup.object().shape({
      approxTurnover: yup.number().required(messages.general),
    }),
  })

const makeELValidationSchema = (messages: ValidationMessages) =>
  yup.object().shape({
    ...makeGeneralValidationObject(messages),
    lastYearFinances: yup.object().shape({
      approxTurnover: yup.number().required(messages.general),
      approxPersonelExpenses: yup.number().required(messages.general),
    }),
    employeesCount: makeEmployeeCountValidationObject(messages),
  })

export const makeValidationSchema = (
  messages: ValidationMessages,
  categoryId: string,
) => {
  switch (categoryId) {
    case 'LI':
    case 'FL':
      return makeLIValidationSchema(messages)
    case 'CO':
      return makeCOValidationSchema(messages)
    case 'LE':
      return makeLEValidationSchema(messages)
    case 'CY':
      return makeCYValidationSchema(messages)
    case 'EL':
      return makeELValidationSchema(messages)
    default:
      return yup.object()
  }
}
