import { ThemeColor } from '@/enums'
import { isNumber } from '@/utils'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Flex } from '../../styled'
import { Typography } from '../Typography'
import { StyledButton } from './styles'

const Button = ({ children, ...rest }) => (
  <StyledButton {...rest}>
    <Typography color={ThemeColor.b50} variant="button">
      {children}
    </Typography>
  </StyledButton>
)

Button.propTypes = {
  children: PropTypes.string,
}

export const PlusMinus = ({ value, onChange, min, max }) => {
  const minDisabled = useMemo(
    () => !isNumber(value) || (isNumber(min) && value <= min),
    [min, value],
  )
  const maxDisabled = useMemo(() => isNumber(max) && value >= max, [max, value])

  const handleMinusClick = () => {
    if (minDisabled) return
    onChange(value - 1)
  }

  const handlePlusClick = () => {
    if (maxDisabled) return
    onChange(parseFloat(value || 0) + 1)
  }

  return (
    <Flex gap="8px">
      <Button disabled={minDisabled} onClick={handleMinusClick}>
        -
      </Button>
      <Button disabled={maxDisabled} onClick={handlePlusClick}>
        +
      </Button>
    </Flex>
  )
}

PlusMinus.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
}
