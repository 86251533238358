import {
  CategoryIcon,
  Column,
  InputWithAddon,
  Loader,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import { useGoToPath } from '@/utils'
import { Search } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  CardsGrid,
  InsuranceCardContainer,
  MostPopularCard,
  PageContainer,
  StyledPageMainContent,
} from './styles'

interface PopularCardProps {
  title: string
  subtitle: string
  variant?: 'default' | 'highlighted'
  categoryId: string
  locationId: string
}

const PopularCard = ({
  title,
  subtitle,
  variant = 'default',
  categoryId,
  locationId,
}: PopularCardProps) => {
  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({
      categoryId,
      locationId,
      insuranceId: undefined,
    }),
  )

  return (
    <MostPopularCard variant={variant} onClick={goToInsurance}>
      <Column gap="8px">
        <CategoryIcon categoryId={categoryId} />
        <Column>
          <Typography variant="body">{title}</Typography>
          <Typography color={ThemeColor.glass500} variant="description">
            {subtitle}
          </Typography>
        </Column>
      </Column>
    </MostPopularCard>
  )
}

const InsuranceCard = ({
  title,
  subtitle,
  categoryId,
  locationId,
}: PopularCardProps) => {
  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({
      categoryId,
      locationId,
      insuranceId: undefined,
    }),
  )

  return (
    <InsuranceCardContainer onClick={goToInsurance}>
      <Typography variant="captionB">{title}</Typography>
      <Typography color={ThemeColor.b50} variant="p2Body">
        {subtitle}
      </Typography>
    </InsuranceCardContainer>
  )
}

interface InsuranceItem {
  value: string
  title: string
  subtitle: string
}

interface InsuranceCategory {
  groupName: string
  items: InsuranceItem[]
}

const filterInsuranceCategories = (
  categories: InsuranceCategory[],
  searchTerm: string,
): InsuranceCategory[] => {
  if (!searchTerm) return categories

  const normalizedSearch = searchTerm.toLowerCase().trim()

  return categories
    .map((category) => ({
      ...category,
      items: category.items.filter((item) =>
        item.title?.toLowerCase().includes(normalizedSearch),
      ),
    }))
    .filter((category) => category.items.length > 0)
}

export const FindInsurancesPage = () => {
  const { t } = useTranslation()
  const { locationId } = useParams()
  const [mostPopular, ...otherGroups] = useInsuranceGroups()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredCategories = useMemo(() => {
    return filterInsuranceCategories(otherGroups, searchTerm)
  }, [otherGroups, searchTerm])

  if (!locationId) {
    return <Loader />
  }

  return (
    <>
      <StyledPageMainContent>
        <PageContainer>
          <Column gap="8px">
            <Typography variant="itemTitle">
              {t('insuranceFinderTranslations.insuranceFinderTitle')}
            </Typography>
            <Typography color={ThemeColor.glass500} variant="description">
              {t('insuranceFinderTranslations.insuranceFinderSubtitle')}
            </Typography>
          </Column>
          <InputWithAddon
            addonBefore={() => (
              <Search color={theme.color.b50} height={16} width={16} />
            )}
            onChange={handleSearchChange}
            placeholder={t('insuranceFinderTranslations.searchPlaceholder')}
            rounded
            value={searchTerm}
          />
          <Column gap="24px">
            {!searchTerm && (
              <Column gap="8px">
                <Typography color={ThemeColor.glass500} variant="subheadline">
                  {mostPopular?.groupName}
                </Typography>
                <CardsGrid>
                  {mostPopular.items.map((insurance) => (
                    <PopularCard
                      key={`${mostPopular.groupName}-${insurance.value}`}
                      categoryId={insurance.value}
                      title={insurance.title}
                      subtitle={insurance.subtitle}
                      locationId={locationId}
                    />
                  ))}
                </CardsGrid>
              </Column>
            )}

            {filteredCategories.map((group) => (
              <Column key={group.groupName} gap="8px">
                <Typography color={ThemeColor.glass500} variant="subheadline">
                  {group.groupName}
                </Typography>
                {group.items.map((insurance) => (
                  <InsuranceCard
                    key={`${group.groupName}-${insurance.value}`}
                    categoryId={insurance.value}
                    title={insurance.title}
                    subtitle={insurance.subtitle}
                    locationId={locationId}
                  />
                ))}
              </Column>
            ))}
          </Column>
        </PageContainer>
      </StyledPageMainContent>
    </>
  )
}
