import { Column, Typography } from '@/components'
import { FormNumberInput } from '@/components/molecules/FormItem/FormNumberInput'
import { useTranslation } from 'react-i18next'

export const EmployeeCount = () => {
  const { t } = useTranslation()
  return (
    <Column gap="16px">
      <Typography bold variant="h5">
        {t('requestOfferPage.employeesCount')}
      </Typography>
      <FormNumberInput
        initialValue={0}
        label={t('employeesCountOwners')}
        min={0}
        name="employeesCount.ownersCount"
        validateOnChange
        withButtons
      />
      <FormNumberInput
        initialValue={0}
        label={t('employeesCountFullTime')}
        min={0}
        name="employeesCount.fullTimeEmployeesCount"
        withButtons
      />
      <FormNumberInput
        initialValue={0}
        label={t('employeesCountpartTime')}
        min={0}
        name="employeesCount.partTimeEmployeesCount"
        withButtons
      />
      <FormNumberInput
        initialValue={0}
        label={t('employeesCountMiniJobbers')}
        min={0}
        name="employeesCount.miniJobbersCount"
        withButtons
      />
    </Column>
  )
}
