import { Loader } from '@/components'
import { AssessmentStatus, AssessmentStepKeys, AssessmentType } from '@/enums'
import { paths } from '@/routes/paths'
import { Redirect } from '@/routes/Redirect'
import { useAssessment } from '@/services'
import { goToExternalUrl, returns, safeTimestamp, useGoToPath } from '@/utils'
import { append, equals, evolve, when } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AssessmentStepper } from './AssessmentStepper'

const transform = evolve({
  values: evolve({
    companyFoundedDate: evolve({
      foundedDate: safeTimestamp,
    }),
    howCanWeHelp: evolve({
      lookingFor: when(equals([]), returns(['liability'])),
    }),
  }),
})

const useMessages = () => {
  const { t } = useTranslation()

  return {
    assessmentSuccessSubtitle: t('assessmentSuccessSubtitle'),
    assessmentSuccessTitle: t('assessmentSuccessTitle'),
    assessmentSuccessButton: t('assessmentSuccessButton'),
    steps: [
      t('assessmentLoadingStep1'),
      t('assessmentLoadingStep2'),
      t('assessmentLoadingStep3'),
      t('assessmentLoadingStep4'),
      t('assessmentLoadingStep5'),
    ],
    subtitle: t('stepsSubtitle'),
    titles: {
      [AssessmentStepKeys.howCanWeHelp]: t('howCanWeHelpStepTitle'),
      [AssessmentStepKeys.userProfile]: t('userProfileStepTitle'),
      [AssessmentStepKeys.businessType]: t('businessTypeTitle'),
      [AssessmentStepKeys.businessTypeB]: t('businessTypeTitle'),
      [AssessmentStepKeys.companyDetails]: t('companyDetailsTitle'),
      [AssessmentStepKeys.companyFoundedDate]: t('companyFoundedDateTitle'),
      [AssessmentStepKeys.locationsCount]: t('locationCountTitle'),
      [AssessmentStepKeys.locationDetails]: t('locationDetailsTitle'),
      [AssessmentStepKeys.locationOpenSeason]: t('locationOpenSeasonTitle'),
      [AssessmentStepKeys.employeesCount]: t('employeesCountTitle'),
      [AssessmentStepKeys.lastYearFinances]: t('lastYearFinancesStep'),
      [AssessmentStepKeys.locationActivities]: t('locationActivitiesTitle'),
      [AssessmentStepKeys.locationRent]: t('locationRentTitle'),
      [AssessmentStepKeys.currentInsurances]: t('currentInsurancesTitle'),
      [AssessmentStepKeys.powerOfAttorney]: t('signPoa_title_assessment'),
      [AssessmentStepKeys.prepareData]: t('assessmentLoadingTitle'),
    },
  }
}

const makeStepProps = (stepsMessages) => ({
  [AssessmentStepKeys.prepareData]: {
    stepsMessages,
  },
})

export const SelfAssessmentPage = () => {
  const messages = useMessages()
  const { locationId } = useParams()

  const { data, isLoading } = useAssessment(locationId, { select: transform })

  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))
  const goToLandingPage = () => goToExternalUrl('https://www.surein.de/')

  if (isLoading) return <Loader />

  if (data.status === AssessmentStatus.completed)
    return <Redirect to={paths.viewLocationWithKey(locationId)} />

  return (
    <AssessmentStepper
      hideLastStepButton
      initialStep={data.currentStep}
      initialValues={data.values}
      messages={messages}
      onFinish={goToLocation}
      onLogoClick={
        data.type === AssessmentType.firstTimeUser
          ? goToLandingPage
          : goToLocation
      }
      stepKeys={append(AssessmentStepKeys.prepareData, data.steps)}
      stepProps={makeStepProps(messages.steps)}
      type={data.type}
    />
  )
}
