import { Column, PageMainContent } from '@/components'
import { Breakpoint } from '@/enums'
import { themeBreakpointDown } from '@/utils'
import { Card } from '@surein/ui'
import styled from 'styled-components'

export const StyledPageMainContent = styled(PageMainContent)`
  grid-column: 4 col-start / span 8;
`

export const PageContainer = styled(Column)`
  gap: 32px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding-top: 16px;
  }
`

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-template-columns: 1fr;
  }
`

export const MostPopularCard = styled(Card)`
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
`

export const InsuranceCardContainer = styled(Column)`
  padding: 8px 12px;
  gap: 4px;
  width: 100%;
  cursor: pointer;
`
