import { insuranceCardData } from '@/components/organisms/InsuranceFinderCard/helpers'
import { ThemeColor } from '@/enums'
import { TickIcon } from '@/icons'
import { openInNewTab, renderableType, useItemDialog } from '@/utils'
import { Button } from '@surein/ui'
import { Upload } from 'lucide-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import {
  Card,
  Column,
  FilePicker,
  Row,
  ShowWhen,
  Typography,
} from '../../../components'
import { config } from '../../../config'
import { Graphic } from '../Graphic'
import { UploadContractModal } from '../UploadContractModal'
import { useMessages } from '../helpers'
import {
  CardFeaturesContainer,
  DefaultInsuranceDetails,
  InsuranceDefaultViewWrapper,
  PageActions,
  TopCardTitle,
} from '../styles'

export const InsuranceCustomBaseView = ({
  popoverContent,
  graphicSection = 1,
}) => {
  const { categoryId } = useParams()

  const messages = useMessages(categoryId)
  const contentTranslations = messages.finderSectionTranslations

  const {
    item: files,
    openItemDialog,
    isOpen,
    closeItemDialog,
  } = useItemDialog()

  const { featureIcons = [] } = insuranceCardData[categoryId] || {}

  return (
    <InsuranceDefaultViewWrapper>
      <DefaultInsuranceDetails>
        <Card dark>
          <Column gap="16px">
            <TopCardTitle>
              <Typography variant="h4">
                {contentTranslations.topCard.title}
              </Typography>
            </TopCardTitle>
            {messages.features.map((feature, i) => {
              const Icon = featureIcons[i]

              return (
                <Row gap="16px">
                  <div>
                    <Icon color={ThemeColor.b50} size="button" stroke />
                  </div>
                  <Typography variant="p2Body">{feature}</Typography>
                </Row>
              )
            })}
          </Column>
        </Card>
        <Column gap="28px">
          <Typography variant="p1Body">
            {contentTranslations.sectionOneTitle}
          </Typography>
          <ShowWhen when={contentTranslations.sectionOneDescription}>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {contentTranslations.sectionOneDescription}
            </Typography>
          </ShowWhen>
          <ShowWhen
            when={contentTranslations.sectionOneDescriptionList?.length}
          >
            <ul>
              {contentTranslations.sectionOneDescriptionList?.map((item) => (
                <li>
                  <Typography color={ThemeColor.b50} variant="p1Body">
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </ShowWhen>
          <ShowWhen when={graphicSection === 1}>
            <Graphic categoryId={categoryId} popoverContent={popoverContent} />
          </ShowWhen>
          <ShowWhen when={contentTranslations.sectionOneExtendedDescription}>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {contentTranslations.sectionOneExtendedDescription}
            </Typography>
          </ShowWhen>
        </Column>
        <Column gap="16px">
          <Typography variant="p1Body">
            {contentTranslations.sectionTwoTitle}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {contentTranslations.sectionTwoDescription}
          </Typography>
        </Column>
        <ShowWhen when={graphicSection === 2}>
          <Graphic categoryId={categoryId} popoverContent={popoverContent} />
        </ShowWhen>
        <ShowWhen
          when={
            contentTranslations.sectionThreeTitle ||
            contentTranslations.sectionThreeDescription
          }
        >
          <Column gap="16px">
            <Typography variant="p1Body">
              {contentTranslations.sectionThreeTitle}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {contentTranslations.sectionThreeDescription}
            </Typography>
          </Column>
        </ShowWhen>
        <Column gap="16px">
          <ShowWhen when={contentTranslations.featuresTitle}>
            <Typography variant="p1Body">
              {contentTranslations.featuresTitle}
            </Typography>
          </ShowWhen>
          <CardFeaturesContainer>
            {contentTranslations.featuresList.map((item) => (
              <Row key={item.title} alignItems="start" gap="16px">
                <TickIcon size="title" />
                <Column>
                  <Typography variant="p1Body">{item.title}</Typography>
                  <ShowWhen when={item.description}>
                    <Typography color={ThemeColor.b50} variant="p1Body">
                      {item.description}
                    </Typography>
                  </ShowWhen>
                </Column>
              </Row>
            ))}
          </CardFeaturesContainer>
        </Column>
        <Card dark padding="20px">
          <Column gap="16px">
            <Typography block color={ThemeColor.b100} variant="p1Body">
              {contentTranslations.contactExpertData.title}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {contentTranslations.contactExpertData.description}
            </Typography>
            <Row>
              <Button
                onClick={() => openInNewTab(config.appointmentUrl)}
                variant="primary"
                shape="square"
              >
                {contentTranslations.contactExpertData.buttonText}
              </Button>
            </Row>
          </Column>
        </Card>
        <PageActions>
          <Column alignItems="flex-start" gap="32px">
            <Column gap="16px">
              <Typography responsive variant="p1Body">
                {messages.haveAContract}
              </Typography>
              <Typography color={ThemeColor.b50} variant="p1Body">
                {messages.uploadContract}
              </Typography>
            </Column>
            <FilePicker accept=".pdf,.jpg" onPick={openItemDialog}>
              <Button shape="square" variant="muted" suffixIcon={Upload}>
                {messages.uploadExistingContract}
              </Button>
            </FilePicker>
          </Column>
        </PageActions>
      </DefaultInsuranceDetails>
      {isOpen && (
        <UploadContractModal files={files} onClose={closeItemDialog} />
      )}
    </InsuranceDefaultViewWrapper>
  )
}

InsuranceCustomBaseView.propTypes = {
  graphicSection: PropTypes.oneOf([1, 2]),
  popoverContent: renderableType,
}
