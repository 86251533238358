import { Column, Typography } from '@/components'
import { FormNumberInput } from '@/components/molecules/FormItem/FormNumberInput'
import { Euro } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type InputType =
  | 'approxTurnover'
  | 'approxPersonelExpenses'
  | 'totalEquipmentsCost'
  | 'approxCostOfGoods'

const config: Record<string, InputType[]> = {
  LI: ['approxTurnover', 'approxPersonelExpenses'],
  CO: ['approxTurnover', 'totalEquipmentsCost', 'approxCostOfGoods'],
  LE: ['approxTurnover'],
  FL: ['approxTurnover', 'approxPersonelExpenses'],
  CY: ['approxTurnover'],
  EL: ['approxTurnover', 'approxPersonelExpenses'],
  default: [
    'approxTurnover',
    'approxPersonelExpenses',
    'totalEquipmentsCost',
    'approxCostOfGoods',
  ],
}

interface FinancialDetailsProps {
  categoryId: string
}

export const FinancialDetails = ({ categoryId }: FinancialDetailsProps) => {
  const { t } = useTranslation()

  const Inputs: Record<string, JSX.Element> = {
    approxPersonelExpenses: (
      <FormNumberInput
        label={t('requestOfferPage.approxPersonelExpensesLabel')}
        infoMessage={t('requestOfferPage.financialDetailsInfoMessage')}
        name="lastYearFinances.approxPersonelExpenses"
        suffixIcon={Euro}
      />
    ),
    approxTurnover: (
      <FormNumberInput
        label={t('requestOfferPage.approxTurnoverLabel')}
        infoMessage={t('requestOfferPage.financialDetailsInfoMessage')}
        name="lastYearFinances.approxTurnover"
        suffixIcon={Euro}
      />
    ),
    totalEquipmentsCost: (
      <FormNumberInput
        label={t('requestOfferPage.totalEquipmentsCostLabel')}
        infoMessage={t('requestOfferPage.financialDetailsInfoMessage')}
        name="locationRent.totalEquipmentsCost"
        suffixIcon={Euro}
      />
    ),
    approxCostOfGoods: (
      <FormNumberInput
        label={t('requestOfferPage.approxCostOfGoodsLabel')}
        infoMessage={t('requestOfferPage.financialDetailsInfoMessage')}
        name="lastYearFinances.approxCostOfGoods"
        suffixIcon={Euro}
      />
    ),
    approximateCostOfElectroEquipment: (
      <FormNumberInput
        label={t('requestOfferPage.approximateCostOfElectroEquipmentLabel')}
        infoMessage={t('requestOfferPage.financialDetailsInfoMessage')}
        name="lastYearFinances.approximateCostOfElectroEquipment"
        suffixIcon={Euro}
      />
    ),
  }

  return (
    <Column gap="16px">
      <Typography bold variant="h5">
        {t('requestOfferPage.financialDetails')}
      </Typography>
      <Column gap="16px">
        {(config?.[categoryId] || config.default).map((key) => (
          <div key={key}>{Inputs[key]}</div>
        ))}
      </Column>
    </Column>
  )
}
