import { Column, Typography } from '@/components'
import { BusinessTypeStep } from '@/pages/SelfAssessmentPage/steps/BusinessTypeStep'
import { useTranslation } from 'react-i18next'

export const BusinessType = () => {
  const { t } = useTranslation()
  return (
    <Column gap="32px">
      <Typography bold variant="h5">
        {t('requestOfferPage.mainBusinessActivity')}
      </Typography>
      <Column>
        <BusinessTypeStep />
      </Column>
    </Column>
  )
}
