import { Column, FormAddressAutocomplete, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useTranslation } from 'react-i18next'

export const RiskLocation = () => {
  const { t } = useTranslation()

  return (
    <Column gap="8px">
      <Column gap="4px">
        <Typography bold variant="h5">
          {t('requestOfferPage.riskLocationTitle')}
        </Typography>
        <Typography color={ThemeColor.glass500} variant="description">
          {t('requestOfferPage.riskLocationSubtitle')}
        </Typography>
      </Column>
      <FormAddressAutocomplete name="locationDetails" />
    </Column>
  )
}
