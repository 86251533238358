import { toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Column, Row } from '../../components/styled'

export const InsurancesContainer = styled.div`
  width: 100%;
`

export const InsuranceCardTextSection = styled(Column)`
  gap: 4px;
  > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const InsurancesHeader = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    border-radius: 50%;
    object-fit: cover;
  }
`

export const AddinsuranceContainer = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const InsuranceCardsContainer = styled(Column)`
  width: 100%;
  align-items: center;
  gap: ${toThemePixels(6)};
  margin-top: ${toThemePixels(6)};
  margin-bottom: ${toThemePixels(15)};
`

export const MiddleSectionTop = styled(Column)`
  margin-bottom: ${toThemePixels(6)};
`

export const UpsellCardsContainer = styled(Column)`
  margin-top: ${toThemePixels(6)};
  margin-bottom: ${toThemePixels(10)};
`

export const ChecklistWidgetContainer = styled(Column)`
  margin-left: ${ifProp('isMobile', '0', toThemePixels(6))};
  margin-bottom: ${ifProp('isMobile', toThemePixels(8), '0')};
`
