import { AdvisorBubble, FormInputField, Loader } from '@/components'
import { FormInputWithDropdown } from '@/components/molecules/FormItem/FormInputWithDropdown'
import { BusinessOrgs } from '@/enums'
import { StyledLinkTypography } from '@/pages/SelfAssessmentPage/steps/styles'
import { FormProvider, useContactModal, useFormContext } from '@/providers'
import {
  usePublicCheckoutCompany,
  useUpdatePublicCheckoutCompany,
} from '@/services'
import { themeBreakpointDown, useDebouncedCallback } from '@/utils'
import { Alert } from '@surein/ui'
import { InfoIcon } from 'lucide-react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { config } from '../../../config'
import { makeCompanyValidationSchema } from './validationSchema'

const Container = styled.div`
  display: flex;
  gap: 1rem;

  ${themeBreakpointDown('md')} {
    flex-direction: column;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`

const StreetField = styled.div`
  flex: 4;

  ${themeBreakpointDown('md')} {
    flex: none;
  }
`

const StyledLink = styled(StyledLinkTypography)`
  font-weight: 700;
`

function convertToOptions(values) {
  return Object.keys(values).map((key) => ({
    value: key,
    label: values[key],
  }))
}

const useAutocomplete = () => {
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null)
  const [placesService, setPlacesService] = useState(null)

  const loadScript = (url, callback) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.onload = callback
    document.head.appendChild(script)
  }

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googlePlacesApiKey}&libraries=places`,
        () => {
          const autocompleteServiceInstance = new window.google.maps.places.AutocompleteService()
          setAutocompleteService(autocompleteServiceInstance)
          const placesServiceInstance = new window.google.maps.places.PlacesService(
            document.createElement('div'),
          )
          setPlacesService(placesServiceInstance)
        },
      )
    } else {
      const autocompleteServiceInstance = new window.google.maps.places.AutocompleteService()
      setAutocompleteService(autocompleteServiceInstance)
      const placesServiceInstance = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      )
      setPlacesService(placesServiceInstance)
    }
  }, [])

  const fetchAutocompleteSuggestions = useDebouncedCallback((input) => {
    if (input.length < 3 || !autocompleteService) return

    autocompleteService.getPlacePredictions(
      { input, componentRestrictions: { country: 'DE' } },
      (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('Error fetching autocomplete suggestions:', status)
          return
        }

        const options = (predictions || []).slice(0, 5).map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id,
        }))

        setDropdownOptions(options)
      },
    )
  }, 300)

  const getPlaceDetails = useCallback(
    (placeId, callback) => {
      if (!placesService) return

      placesService.getDetails({ placeId }, (place, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('Error fetching place details:', status)
          callback(null)
          return
        }

        callback(place)
      })
    },
    [placesService],
  )

  return {
    dropdownOptions,
    fetchAutocompleteSuggestions,
    getPlaceDetails,
  }
}

// ! TODO: Refactor this form into a reusable component so that the autocomplete logic can be reused across the app

const CompanyDetailsForm = ({ setButtonState }) => {
  const { t } = useTranslation()
  const { submitForm, errors, customValidate, setFieldValue } = useFormContext()
  const { openContact } = useContactModal()
  const {
    dropdownOptions,
    fetchAutocompleteSuggestions,
    getPlaceDetails,
  } = useAutocomplete()

  useEffect(() => {
    setButtonState?.({
      onClick: () =>
        customValidate({
          onSuccess: submitForm,
        }),
    })
  }, [])

  const handleDropdownItemSelect = (option) => {
    const placeId = option.value
    getPlaceDetails(placeId, (place) => {
      if (place) {
        const addressComponents = place.address_components

        const getAddressComponent = (type) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name || ''

        const streetNumber = getAddressComponent('street_number')
        const route = getAddressComponent('route')
        const postalCode = getAddressComponent('postal_code')
        const city = getAddressComponent('locality')

        setFieldValue('name', place.name)
        setFieldValue('address.street', route)
        setFieldValue('address.streetNr', streetNumber)
        setFieldValue('address.postalCode', postalCode)
        setFieldValue('address.city', city)
      }
    })
  }

  return (
    <>
      {!isEmpty(errors) && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={
            <Trans
              components={
                <StyledLink onClick={openContact} variant="captionB" />
              }
              i18nKey={t('companyDetailsErrorAlert')}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}

      <FormInputWithDropdown
        dropDownOptions={dropdownOptions}
        label={t('businessName')}
        name="name"
        onDropdownItemSelect={handleDropdownItemSelect}
        onSearchInput={fetchAutocompleteSuggestions}
        options={convertToOptions(BusinessOrgs)}
      />

      <Container>
        <StreetField>
          <FormInputField
            validateOnChange
            label={t('street')}
            name="address.street"
          />
        </StreetField>
        <div className="flex-1">
          <FormInputField
            validateOnChange
            label={t('streetNr')}
            name="address.streetNr"
          />
        </div>
      </Container>

      <FormInputField
        label={t('postCode')}
        name="address.postalCode"
        type="number"
      />
      <FormInputField label={t('city')} name="address.city" />
    </>
  )
}

CompanyDetailsForm.propTypes = {
  setButtonState: PropTypes.func,
}

export const PublicCompanyDetailsStep = ({ onNext, setButtonState }) => {
  const { t } = useTranslation()
  const { data, isLoading } = usePublicCheckoutCompany()

  const updateCompanyMutation = useUpdatePublicCheckoutCompany()

  const handleSubmit = ({ name, address }) => {
    updateCompanyMutation.mutate({ name, address }, { onSuccess: onNext })
  }

  useEffect(() => {
    setButtonState({
      loading: updateCompanyMutation.isLoading,
    })
  }, [updateCompanyMutation.isLoading])

  if (isLoading) return <Loader />

  return (
    <FormProvider
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={makeCompanyValidationSchema(
        t('validations', { returnObjects: true }),
      )}
    >
      <Wrapper>
        <CompanyDetailsForm setButtonState={setButtonState} />
        <AdvisorBubble text={t('businessInformationAdvisoryLabel')} />
      </Wrapper>
    </FormProvider>
  )
}

PublicCompanyDetailsStep.propTypes = {
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
