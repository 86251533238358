import { theme } from '@/theme'
import {
  Building2,
  Car,
  Coins,
  Euro,
  Home,
  Laptop,
  Monitor,
  Package,
  Scale,
  Truck,
  Umbrella,
} from 'lucide-react'
import PropTypes from 'prop-types'

const getIcon = (category) => {
  switch (category) {
    case 'LI':
      return Umbrella
    case 'CO':
      return Package
    case 'LE':
      return Scale
    case 'CA':
      return Car
    case 'BU':
      return Building2
    case 'RE':
      return Home
    case 'CY':
    case 'MI':
      return Laptop
    case 'TR':
      return Truck
    case 'EL':
      return Monitor
    case 'FL':
      return Coins
    default:
      return Euro
  }
}

export const CategoryIcon = ({ categoryId }) => {
  const Icon = getIcon(categoryId)

  return <Icon color={theme.color.info1} />
}
CategoryIcon.propTypes = {
  categoryId: PropTypes.string,
}
