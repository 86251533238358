import { RecommendationCardBase } from '@/components'
import { useSegment } from '@/modules'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export const MissingRecommendationCard = ({
  categoryId,
  important = false,
}) => {
  const { t } = useTranslation()
  const { locationId } = useParams()
  const analytics = useSegment()

  const goToRequestOffer = useGoToPath(
    paths.requestOfferWithKey({ locationId, categoryId }),
  )

  const handleClick = async () => {
    await analytics.track('app_request_offer', {
      categoryId,
      source: 'dashboard',
    })
    goToRequestOffer()
  }

  return (
    <RecommendationCardBase
      categoryId={categoryId}
      important={important}
      primaryText={t('getOfferButtonText')}
      inlineButton
      onButtonClick={handleClick}
      onCardClick={handleClick}
    />
  )
}

MissingRecommendationCard.propTypes = {
  categoryId: PropTypes.string.isRequired,
  important: PropTypes.bool,
}
