import { Column, FormMonthSelect, Typography } from '@/components'
import { currentYear } from '@/utils'
import { useTranslation } from 'react-i18next'

export const CompanyDetails = () => {
  const { t } = useTranslation()
  return (
    <Column gap="16px">
      <Typography bold variant="h5">
        {t('requestOfferPage.companyDetails')}
      </Typography>
      {/* @ts-expect-error fix type */}
      <FormMonthSelect
        label={t('requestOfferPage.foundedDateLabel')}
        maxDate={new Date(currentYear(), 11, 31, 12)}
        name="companyFoundedDate.foundedDate"
      />
    </Column>
  )
}
