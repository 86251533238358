import {
  Absolute,
  Column,
  Header,
  ImageFromCdn,
  Row,
  TrustPartners,
  Typography,
} from '@/components'
import { config } from '@/config'
import { Breakpoint, ThemeColor } from '@/enums'
import { TrustPilotStarIcon } from '@/icons'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'
import { Faqs } from '@/pages/RecommendationPage/Faqs'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import {
  goToExternalUrl,
  openInNewTab,
  themeBreakpointDown,
  themeColor,
  useGoToPath,
} from '@/utils'
import { Button } from '@surein/ui'
import { CheckIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import Masonry from 'react-masonry-css'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import PropTypes from 'prop-types'

const NoOfferPageContainer = styled(Column)`
  padding-top: 128px;
  background: ${themeColor(ThemeColor.b0)};
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 40px;

  h1,
  h2,
  h3 {
    hyphens: none;
  }
`

const TitleSection = styled(Column)`
  gap: 24px;
  align-items: center;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: 0 16px;
    align-items: normal;
  }
`

const SizeWrapper = styled(Column)`
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`

const HeadingText = styled(Column)`
  max-width: 768px;
  text-align: center;
  gap: 8px;
  margin: 0 auto;

  > span {
    font-weight: 600;
  }
`

const TextSection = styled(Column)`
  max-width: 860px;
  padding: 40px 16px;
  align-items: center;
  gap: 48px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    align-items: normal;
  }
`

const ThreeColGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin-top: 16px;

  > div {
    gap: 8px;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    grid-template-columns: 1fr;
  }
`

const GridItem = styled.div`
  background-color: ${themeColor(ThemeColor.glass100)};
  border: 1px solid ${themeColor(ThemeColor.glass200)};
  border-radius: 24px;
  padding: 32px;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

const TextItem = styled(Column)`
  gap: 8px;
`

const MasonryContainer = styled(Masonry)`
  display: flex;
  margin-left: -16px; /* Compensate for column gap */
  width: auto;
`

const UserHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: ${prop('color')};
  flex-shrink: 0;

  &:before {
    content: '${prop('avatarText')}';
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: ${themeColor(ThemeColor.b100)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const FaqsContainer = styled(Column)`
  width: 100%;

  > div {
    width: 100%;
  }
`

const NextStepNumber = styled(Column)`
  background: ${themeColor(ThemeColor.info1)};
  color: ${themeColor(ThemeColor.glass0)};
  border-radius: 12px;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
`

const VideoSection = styled(Column)`
  min-height: 100vh;
  position: relative;
  width: 100%;
  padding: 40px 16px;
  background: ${themeColor(ThemeColor.glass500)};
  overflow: hidden;
  align-items: flex-end;
  justify-content: center;

  > iframe {
    z-index: 0;
    background: ${themeColor(ThemeColor.b0)};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.5);
    filter: brightness(0.4);
  }

  > div {
    z-index: 1;
    padding: 40px 16px;
  }

  * {
    color: ${themeColor(ThemeColor.glass0)};
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    iframe {
      transform: scale(4);
    }
  }
`

const CheckCircle = styled.div`
  background: ${themeColor(ThemeColor.b40)};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`

const ShredderVideoSection = styled(Column)`
  height: 60vh;
  position: relative;
  width: 100%;
  background: ${themeColor(ThemeColor.glass500)};
  overflow: hidden;
  align-items: flex-center;
  justify-content: center;

  > iframe {
    z-index: 0;
    background: ${themeColor(ThemeColor.b0)};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(2.5);
    filter: brightness(0.4);
  }

  > div {
    z-index: 1;
    padding: 40px 16px;
    margin: 0 auto;
  }

  * {
    color: ${themeColor(ThemeColor.glass0)};
  }
`

const TestimonialsWrapper = styled(Column)`
  max-width: 1220px;
  padding: 40px 0;
  gap: 24px;

  ${themeBreakpointDown(Breakpoint.md)} {
    padding-left: 12px;
  }
`

const WhatDoesThisMeanText = styled(Column)`
  gap: 24px;
  max-width: 50%;

  ${themeBreakpointDown(Breakpoint.md)} {
    max-width: 100%;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 224px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  ${themeBreakpointDown('sm')} {
    img {
      object-position: center;
    }
  }
`

const TrustPartnersSpacer = styled.div`
  margin-top: 48px;
`

// Sample review data with variable heights
const reviews = [
  {
    id: 1,
    username: 'Kunde Qendrim Nuredini',
    avatarText: 'KQ',
    avatarColor: '#fcfcd4',
    textTranslationKey: 'customer1',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Mar 26, 2024',
  },
  {
    id: 2,
    username: 'Axel von Salz-Dienstleistungen',
    avatarText: 'AV',
    avatarColor: '#d2faeb',
    textTranslationKey: 'customer2',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Mar 17, 2024',
  },
  {
    id: 3,
    username: 'Carlo Kempfle',
    avatarText: 'CK',
    avatarColor: '#fcfcd4',
    textTranslationKey: 'customer3',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Mar 21, 2024',
  },
  {
    id: 4,
    username: 'Roots Radicals',
    avatarText: 'RR',
    avatarColor: '#ffe0bf',
    textTranslationKey: 'customer4',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Jul 12, 2022',
  },
  {
    id: 5,
    username: 'Antonios Anyfantis',
    avatarText: 'AA',
    avatarColor: '#fcdce6',
    textTranslationKey: 'customer5',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Mar 14, 2024',
  },
  {
    id: 6,
    username: 'Kunde',
    avatarText: 'KU',
    avatarColor: '#d2faeb',
    textTranslationKey: 'customer6',
    trustPilotUrl:
      'https://www.trustpilot.com/review/surein.de?languages=all&stars=5',
    datePosted: 'Feb 6, 2024',
  },
]

const UserReviewMasonry = () => {
  const { t } = useTranslation()
  // Breakpoints for responsive masonry
  const breakpointColumnsObj = {
    default: 3, // 3 columns by default
    1100: 3, // 3 columns at 1100px
    700: 2, // 2 columns at 700px
    500: 1, // 1 column at 500px
  }

  return (
    <MasonryContainer
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_column"
    >
      {reviews.map((review) => (
        <GridItem key={review.id}>
          <UserHeader>
            <Avatar
              src={review.avatar}
              color={review.avatarColor}
              alt={review.username}
              avatarText={review.avatarText}
            />
            <Typography bold variant="description">
              {review.username}
            </Typography>
          </UserHeader>
          <Column gap="16px">
            <Row gap="16px" alignItems="center">
              <TrustPilotStarIcon width={90} />
              <Row alignItems="center" gap="4px">
                <CheckCircle>
                  <CheckIcon color={theme.color.glass0} />
                </CheckCircle>
                <Typography color={ThemeColor.glass500} variant="footnote">
                  {t('noOfferPage.customerTestimonials.verifiedReview')}
                </Typography>
              </Row>
            </Row>
            <Typography variant="description">
              {t(
                `noOfferPage.customerTestimonials.${review.textTranslationKey}`,
              )}
            </Typography>
            <Row gap="8px">
              <Typography
                color={ThemeColor.glass500}
                underline
                variant="description"
              >
                <a href={review.trustPilotUrl} target="_blank" rel="noreferrer">
                  {t('noOfferPage.readInTrustpilot')}
                </a>
              </Typography>
              <Typography color={ThemeColor.glass500} variant="description">
                {review.datePosted}
              </Typography>
            </Row>
          </Column>
        </GridItem>
      ))}
    </MasonryContainer>
  )
}

export const NoOfferPage = ({ isPartner }) => {
  const { t } = useTranslation()

  const handleArrangeMeeting = () => {
    openInNewTab(config.appointmentUrl)
  }

  const goToQonto = () => goToExternalUrl(config.qontoInsuranceHubUrl)
  const goToDashboard = useGoToPath(paths.dashboard)

  return (
    <Absolute left={0} right={0} top={0}>
      <Header backgroundColor={ThemeColor.b0} simple />
      <NoOfferPageContainer>
        <SizeWrapper>
          <TitleSection alignItems="center" gap="24px">
            <HeadingText>
              <Typography color={ThemeColor.glass500} variant="body">
                {t('noOfferPage.headerTitle')}
              </Typography>
              <Typography bold variant="h2">
                {t('noOfferPage.headerSubtitle')}
              </Typography>
              <Typography color={ThemeColor.glass500} variant="body">
                {t('noOfferPage.headerDescription')}
              </Typography>
            </HeadingText>
            <ImageContainer>
              <ImageFromCdn name="team-stack" type="webp" fallbackType="png" />
            </ImageContainer>
            <Row gap="24px">
              <Button
                variant="primary"
                onClick={handleArrangeMeeting}
                shape="square"
              >
                {t('noOfferPage.bookAppointmentButton')}
              </Button>
              <Button
                variant="outline"
                onClick={isPartner ? goToQonto : goToDashboard}
                shape="square"
              >
                {isPartner ? t('backToQonto') : t('assessmentSuccessButton')}
              </Button>
            </Row>
            <TrustPartnersSpacer>
              <TrustPartners hide={{ surein: true }} />
            </TrustPartnersSpacer>
            <TextSection>
              <Typography bold variant="h3">
                {t('noOfferPage.whyDidThisHappen.title')}
              </Typography>
              <ThreeColGrid>
                <TextItem>
                  <Typography bold variant="body">
                    {t('noOfferPage.whyDidThisHappen.specialRisk.title')}
                  </Typography>
                  <Typography color={ThemeColor.glass500} variant="description">
                    {t('noOfferPage.whyDidThisHappen.specialRisk.description')}
                  </Typography>
                </TextItem>
                <TextItem>
                  <Typography bold variant="body">
                    {t('noOfferPage.whyDidThisHappen.uniqueBusiness.title')}
                  </Typography>
                  <Typography color={ThemeColor.glass500} variant="description">
                    {t(
                      'noOfferPage.whyDidThisHappen.uniqueBusiness.description',
                    )}
                  </Typography>
                </TextItem>
                <TextItem>
                  <Typography bold variant="body">
                    {t('noOfferPage.whyDidThisHappen.riskEnquiry.title')}
                  </Typography>
                  <Typography color={ThemeColor.glass500} variant="description">
                    {t('noOfferPage.whyDidThisHappen.riskEnquiry.description')}
                  </Typography>
                </TextItem>
              </ThreeColGrid>
              <Button
                variant="primary"
                onClick={handleArrangeMeeting}
                shape="square"
              >
                {t('noOfferPage.bookAppointmentButton')}
              </Button>
            </TextSection>
          </TitleSection>
        </SizeWrapper>
        <VideoSection>
          <WhatDoesThisMeanText>
            <Column gap="16px">
              <Typography bold variant="h2">
                {t('noOfferPage.whatDoesMeanForMe.title')}
              </Typography>
              <Typography color={ThemeColor.glass500} variant="body">
                {t('noOfferPage.whatDoesMeanForMe.subtitle')}
              </Typography>
            </Column>
            <Column gap="32px">
              {[1, 2, 3].map((index) => (
                <TextItem key={index}>
                  <Typography bold variant="body">
                    {t('noOfferPage.whatDoesMeanForMe.expertSupport.title')}
                  </Typography>
                  <Typography variant="body">
                    {t(
                      'noOfferPage.whatDoesMeanForMe.expertSupport.description',
                    )}
                  </Typography>
                </TextItem>
              ))}
            </Column>
          </WhatDoesThisMeanText>
          <iframe
            src="https://player.vimeo.com/video/987162311?muted=1&autoplay=1&autopause=0&controls=0&loop=1"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          />
        </VideoSection>
        <SizeWrapper>
          <TextSection>
            <Typography bold variant="h3">
              {t('noOfferPage.questions.title')}
            </Typography>
            <ThreeColGrid>
              <TextItem>
                <Typography bold variant="body">
                  {t('noOfferPage.questions.moreInfo.title')}
                </Typography>
                <Typography color={ThemeColor.glass500} variant="description">
                  {t('noOfferPage.questions.moreInfo.description')}
                </Typography>
              </TextItem>
              <TextItem>
                <Typography bold variant="body">
                  {t('noOfferPage.questions.duration.title')}
                </Typography>
                <Typography color={ThemeColor.glass500} variant="description">
                  {t('noOfferPage.questions.duration.description')}
                </Typography>
              </TextItem>
              <TextItem>
                <Typography bold variant="body">
                  {t('noOfferPage.questions.callProcess.title')}
                </Typography>
                <Typography color={ThemeColor.glass500} variant="description">
                  {t('noOfferPage.questions.callProcess.description')}
                </Typography>
              </TextItem>
            </ThreeColGrid>
            <Button
              variant="primary"
              onClick={handleArrangeMeeting}
              shape="square"
            >
              {t('noOfferPage.bookAppointmentButton')}
            </Button>
          </TextSection>
          <TextSection>
            <Typography bold variant="h3">
              {t('noOfferPage.nextSteps.title')}
            </Typography>
            <ThreeColGrid>
              {['step1', 'step2', 'step3'].map((step) => (
                <Column key={step}>
                  <NextStepNumber>
                    <Typography bold variant="p2Body">
                      {t(`noOfferPage.nextSteps.${step}.stepNr`)}
                    </Typography>
                  </NextStepNumber>
                  <Typography bold variant="body">
                    {t(`noOfferPage.nextSteps.${step}.title`)}
                  </Typography>
                  <Typography color={ThemeColor.glass500} variant="description">
                    {t(`noOfferPage.nextSteps.${step}.description`)}
                  </Typography>
                </Column>
              ))}
            </ThreeColGrid>
            <Button
              variant="primary"
              onClick={handleArrangeMeeting}
              shape="square"
            >
              {t('noOfferPage.bookAppointmentButton')}
            </Button>
          </TextSection>
          <TestimonialsWrapper>
            <Column gap="16px" alignItems="center" justifyContent="center">
              <Typography bold variant="h2">
                {t('noOfferPage.customerTestimonials.title')}
              </Typography>
              <Typography color={ThemeColor.glass500} variant="body">
                {t('noOfferPage.customerTestimonials.subtitle')}
              </Typography>
              <TrustPilotExtended width={300} height={60} />
            </Column>
            <UserReviewMasonry />
          </TestimonialsWrapper>
        </SizeWrapper>
        <FaqsContainer>
          <ShredderVideoSection>
            <TextSection>
              <Typography textAlign="center" bold variant="h2">
                {t('noOfferPage.joinUs.title')}
              </Typography>
              <Button
                variant="primary"
                onClick={handleArrangeMeeting}
                shape="square"
              >
                {t('noOfferPage.bookAppointmentButton')}
              </Button>
            </TextSection>
            <iframe
              src="https://player.vimeo.com/video/988326212?muted=1&autoplay=1&autopause=0&controls=0&loop=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            />
          </ShredderVideoSection>
          <Faqs />
        </FaqsContainer>
      </NoOfferPageContainer>
    </Absolute>
  )
}

NoOfferPage.propTypes = {
  isPartner: PropTypes.bool,
}
