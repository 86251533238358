import { GetSelfAssessmentApiResponse } from '@/api'
import { safeTimestamp } from '@/utils'
import { BusinessType } from './Sections/BusinessType'
import { CompanyDetails } from './Sections/CompanyDetails'
import { EmployeeCount } from './Sections/EmployeeCount'
import { FinancialDetails } from './Sections/FinancialDetails'
import { RiskLocation } from './Sections/RiskLocation'

export const autoRecommendationCategories = ['LI', 'CO', 'LE', 'FL', 'CY', 'EL']

const sectionKeys = {
  businessType: 'businessType',
  companyDetails: 'companyDetails',
  financialDetails: 'financialDetails',
  riskLocation: 'riskLocation',
  employeeCount: 'employeeCount',
}

export const sectionComponents: {
  [key: string]: React.ComponentType<{ categoryId: string }>
} = {
  [sectionKeys.businessType]: BusinessType,
  [sectionKeys.companyDetails]: CompanyDetails,
  [sectionKeys.financialDetails]: FinancialDetails,
  [sectionKeys.riskLocation]: RiskLocation,
  [sectionKeys.employeeCount]: EmployeeCount,
}

export const categoryToSectionMap: Record<string, string[]> = {
  LI: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
    sectionKeys.employeeCount,
  ],
  CO: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
    sectionKeys.employeeCount,
    sectionKeys.riskLocation,
  ],
  LE: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
    sectionKeys.employeeCount,
  ],
  FL: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
    sectionKeys.employeeCount,
  ],
  CY: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
  ],
  EL: [
    sectionKeys.businessType,
    sectionKeys.companyDetails,
    sectionKeys.financialDetails,
    sectionKeys.employeeCount,
  ],
}

const makeEmployeesCountDefaultValues = (
  values: GetSelfAssessmentApiResponse['values']['employeesCount'] = null,
) => ({
  ownersCount: values?.ownersCount || 0,
  fullTimeEmployeesCount: values?.fullTimeEmployeesCount || 0,
  partTimeEmployeesCount: values?.partTimeEmployeesCount || 0,
  miniJobbersCount: values?.miniJobbersCount || 0,
})

const getLIValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']>,
) => ({
  ...data.businessType,
  companyFoundedDate: {
    foundedDate: safeTimestamp(data.companyFoundedDate?.foundedDate),
  },
  lastYearFinances: {
    approxTurnover: data.lastYearFinances?.approxTurnover,
    approxPersonelExpenses: data.lastYearFinances?.approxPersonelExpenses,
  },
  employeesCount: makeEmployeesCountDefaultValues(data.employeesCount),
})

const getCOValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']>,
) => ({
  ...data.businessType,
  companyFoundedDate: {
    foundedDate: safeTimestamp(data.companyFoundedDate?.foundedDate),
  },
  lastYearFinances: {
    approxTurnover: data.lastYearFinances?.approxTurnover,
    approxCostOfGoods: data.lastYearFinances?.approxCostOfGoods,
  },
  locationRent: {
    totalEquipmentsCost: data.locationRent?.totalEquipmentsCost,
  },
  employeesCount: makeEmployeesCountDefaultValues(data.employeesCount),
  locationDetails: {
    name: data.locationDetails?.name,
    address: {
      street: data.locationDetails?.address?.street,
      streetNr: data.locationDetails?.address?.streetNr,
      postalCode: data.locationDetails?.address?.postalCode,
      city: data.locationDetails?.address?.city,
    },
  },
})

const getELValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']>,
) => ({
  ...data.businessType,
  companyFoundedDate: {
    foundedDate: safeTimestamp(data.companyFoundedDate?.foundedDate),
  },
  lastYearFinances: {
    approxTurnover: data.lastYearFinances?.approxTurnover,
    approxPersonelExpenses: data.lastYearFinances?.approxPersonelExpenses,
  },
  employeesCount: makeEmployeesCountDefaultValues(data.employeesCount),
})

const getLEValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']>,
) => ({
  ...data.businessType,
  companyFoundedDate: {
    foundedDate: safeTimestamp(data.companyFoundedDate?.foundedDate),
  },
  lastYearFinances: {
    approxTurnover: data.lastYearFinances?.approxTurnover,
  },
  employeesCount: makeEmployeesCountDefaultValues(data.employeesCount),
})

const getCYValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']>,
) => ({
  ...data.businessType,
  companyFoundedDate: {
    foundedDate: safeTimestamp(data.companyFoundedDate?.foundedDate),
  },
  lastYearFinances: {
    approxTurnover: data.lastYearFinances?.approxTurnover,
  },
})

export const makeInitialValues = (
  data: Partial<GetSelfAssessmentApiResponse['values']> = {},
  categoryId: string,
) => {
  switch (categoryId) {
    case 'LI':
      return getLIValues(data)
    case 'CO':
      return getCOValues(data)
    case 'LE':
      return getLEValues(data)
    case 'FL':
      return getLIValues(data)
    case 'EL':
      return getELValues(data)
    case 'CY':
      return getCYValues(data)
    default:
      return {}
  }
}
