import { paths } from '@/routes/paths'
import { useUpdateLocationChecklistSettings } from '@/services'
import { useGoToPath } from '@/utils'
import { Button } from '@surein/ui'
import PropTypes from 'prop-types'
import { assoc, both, filter, map, mergeLeft, pipe, prop, propOr } from 'ramda'

export const prepareChecklist = (messages, data, actionButtons) =>
  pipe(
    propOr([], 'checks'),
    map((item) =>
      pipe(
        mergeLeft(messages[item.type]),
        assoc('actionButtons', actionButtons[item.type]),
      )(item),
    ),
    filter(both(prop('title'), prop('text'))),
  )(data)

export const OnboardingButton = ({ locationId, disabled, text }) => {
  const goToAssessment = useGoToPath(paths.assessmentWithKey(locationId))
  return (
    <Button
      variant="primary"
      disabled={disabled}
      onClick={goToAssessment}
      size="sm"
    >
      {text}
    </Button>
  )
}

export const PoaButton = ({ companyId, disabled, text }) => {
  const goToPoa = useGoToPath(`/company/poa/${companyId}`)
  return (
    <Button variant="primary" disabled={disabled} onClick={goToPoa} size="sm">
      {text}
    </Button>
  )
}

export const InsuranceButtons = ({ locationId, disabled, text }) => {
  const handleAddInsurance = useGoToPath(paths.addInsuranceContract)
  const updateLocationChecklistSettingsMutation = useUpdateLocationChecklistSettings(
    locationId,
  )

  const handleOnClick = () => updateLocationChecklistSettingsMutation.mutate()

  return (
    <>
      <Button
        variant="primary"
        disabled={disabled}
        onClick={handleAddInsurance}
        size="sm"
      >
        {text.text1}
      </Button>
      <Button
        variant="outline"
        disabled={disabled}
        onClick={handleOnClick}
        size="sm"
      >
        {text.text2}
      </Button>
    </>
  )
}

export const ConnectAccountButton = ({ disabled, text }) => {
  const goToConnect = useGoToPath(`/profile/integrations`)
  return (
    <Button
      variant="primary"
      disabled={disabled}
      onClick={goToConnect}
      size="sm"
    >
      {text}
    </Button>
  )
}

ConnectAccountButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
}

OnboardingButton.propTypes = {
  disabled: PropTypes.bool,
  locationId: PropTypes.string,
  text: PropTypes.string,
}

PoaButton.propTypes = {
  companyId: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
}

InsuranceButtons.propTypes = {
  disabled: PropTypes.bool,
  locationId: PropTypes.string,
  text: PropTypes.arrayOf(
    PropTypes.shape({
      text1: PropTypes.string,
      text2: PropTypes.string,
    }),
  ),
}
